import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">Benefits of </span>Our Initial Exchange Offering Development</h2>
              <p className='text-center'>Explore your IEO development benefits with Our Initial Exchange Offering Expertise. Launch with Confidence and trade with ease!</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center" >
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/ieo/benefits-of.webp"
                alt="best ieo development company"
                placeholder='none'
                className='mt-3'
                width={584}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul className='ieolist pl-lg-5 mb-0 mt-4'>
                <li>Access to an Established Investor Base</li>
                <li>Enhanced Credibility  </li>
                <li>Immediate Liquidity and Market Exposure.</li>
                <li>Stability Pricing Factor.</li>
                <li>Regulatory Compliance.</li>
                <li>24/7 Professional Support and Guidance.</li>
                <li>Efficient Token Sale Process.</li>
                <li>Significant Community Engagement and Exposure</li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose